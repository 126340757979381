import React from 'react';

import "./LoadingSpinner.css";

const LoadingSpinner = () => (
  <div className="spinner-wrapper">
    <div className="spinner"/>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
  </div>
);

export default LoadingSpinner;
