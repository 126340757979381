import { useState, useEffect } from "react";

import LoadingSpinner from "./components/LoadingSpinner";
import { CodatLink } from "./components/CodatLink";

import { config as connectedConfig } from "./components/connectedConfig";
import { config as invoiceFinanceConfig } from "./components/invoiceFinanceConfig";

import brex from './components/brex.svg'

import "./App.css";

function App() {
  const [companyId, setCompanyId] = useState("");
  const [productName, setProductName] = useState("connected");
  const [modalOpen, setModalOpen] = useState(false);

  const brexHome = "https://www.brex.com/"

  const onConnection = (connection) =>
    console.log(`Connection | Company: ${companyId} | Connection: ${connection.connectionId}`);
  
  const onFinish = () => {
    console.log(`Success | Company: ${companyId}`);

    window.location.href = brexHome;
  };

  const onError = (error) => {
    console.log(`Connection | Company: ${companyId} | Error: ${error.message}`);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const companyId = queryParams.get('company');
    const productName = queryParams.get('product');

    if (companyId) setCompanyId(companyId)
    if (productName) setProductName(productName)

    console.log(`Start | Company: ${companyId}`);

    setTimeout(() => {
      if(!!companyId) setModalOpen(true)
    }, 1000)
  }, []);


  const productOptions = {
    connected: connectedConfig,
    invoiceFinance: invoiceFinanceConfig,
  }

  return (
    <div className="App">
      <div className={`page ${!!modalOpen && 'modalOpen'}`}>
        <nav className="nav">
          <img src={brex} className="App-logo" alt="logo" />

          <a href="https://privacy.brex.com/">Privacy policy</a>
        </nav>

        {
          !!companyId && (
            <LoadingSpinner/>
          )
        }

        <div className="image-wrapper">
          <img src="https://brand.brex.com/transform/34b1e6c6-3bd8-4879-96e8-8f85c550c68a/HP-Hero-Desktop"/>
        </div>

        {
          !companyId && (
            <div className="row">
              This URL is incorrect. Check your original link.
            </div>
          )
        }
      </div>

      {modalOpen && (
        <CodatLink
          companyId={companyId}
          onConnection={onConnection}
          onError={onError}
          onFinish={onFinish}
          options={productOptions[productName]}
        />
      )}
    </div>
  );
}

export default App;
